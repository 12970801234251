import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const error = () => {
  return (
    <>
      <SEO title="Page Not Found" />
      <Layout>
        <h1>404 Page</h1>
      </Layout>
    </>
  )
}

export default error
